<template>
  <dialog-outline v-model="showDialog">
    {{ dialogTitle }}
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <template v-slot:pre-context>
      <!-- record details banner, which represents a subject that current operation will be performed -->
      <record-banner :record="record"></record-banner>

      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <div class="mt-5">
        <v-select
          v-model="movePageModel.sourcePage"
          :items="totalPages"
          :label="labelSourcePage"
          :prepend-icon="iconPage"
        >
        </v-select>
        <v-select
          v-model="movePageModel.destinationPage"
          :items="totalPages"
          :label="labelDestinationPage"
          :prepend-icon="iconPage"
        >
        </v-select>
      </div>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk">
        {{ dialogOkCommandLabel }}
      </base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// design
import { iconPage } from "@/design/icon/iconConst";

// model
import { findImageOperation, imageOperation } from "@/model/image/imageModel";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { recordBannerAble } from "@/mixins/shared/bannerAble/recordBannerAble";

//model
import { actionResultType } from "@/model/action/actionModel";
import { fullRecordName } from "@/services/record/recordService";

export default {
  name: "MovePageDialog",
  mixins: [dialogOutlineMixin, baseComponentMixin, recordBannerAble],
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    record: undefined,
    totalPages: {
      type: Array,
      required: true
    },
    movePageModel: {
      type: Object,
      default: () => ({
        sourcePage: {
          type: Number,
          default: 1
        },
        destinationPage: {
          type: Number,
          default: 1
        }
      })
    },
    moveEvent: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      iconPage: iconPage
    };
  },

  computed: {
    /**
     * download Operation
     * @return {{name: string, icon: string, label: string}} download Operation
     */
    operation() {
      return findImageOperation(imageOperation.Move);
    },

    /**
     * download operation Label
     * @return {string} download operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Get Move Page Dialog title
     * @return {string} Move Page Dialog title
     */
    dialogTitle() {
      return `${this.operationLabel} Page`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * label Source Page
     * @return {string} label Source Page
     */
    labelSourcePage() {
      return "Source Page Number";
    },

    /**
     * label Destination Page
     * @return {string} label Destination Page
     */
    labelDestinationPage() {
      return "Destination Page Number";
    },
    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} ${
        this.record?.recordType
      }: '${fullRecordName(this.record)}'`;
    }
  },

  methods: {
    /**
     * Performs Move Page
     */
    async onOk() {
      try {
        this.clearAlert();
        const result = await this.moveEvent();
        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      }
    },

    /**
     * Handle on Dialog Opened event in order to initialize data
     */
    onDialogOpened() {
      this.internalAlert = undefined;
    },

    /**
     * Handle on Dialog Closed event in order to clear data
     */
    onDialogClosed() {
      this.internalAlert = undefined;
    }
  }
};
</script>
